import { PaginateProps } from '@eventlr/types/src/Frontend/common';
import { isEqual } from 'lodash';
import Link from 'next/link';

import { usePathname, useSearchParams } from 'next/navigation';
import { memo } from 'react';

function paginatePropsAreEqual(
  prevPaginate: Readonly<{ paginate: PaginateProps }>,
  nextPaginate: Readonly<{ paginate: PaginateProps }>,
) {
  console.log('prevPaginate', prevPaginate);
  console.log('nextPaginate', nextPaginate);
  return isEqual(prevPaginate.paginate, nextPaginate.paginate);
}

export function Paginate({ paginate }: { paginate: PaginateProps }) {
  const pathname = usePathname();
  const searchParams = useSearchParams();

  function buildPath(page: number) {
    const params = new URLSearchParams(searchParams);
    params.set('page', String(page));
    return `${pathname}?${params.toString()}`;
  }

  return (
    <>
      <nav className="ml-1 mr-1 mt-5 px-4 flex items-center justify-between sm:px-0">
        <div className="-mt-px w-0 flex-1 flex">
          {paginate.page > 1 ? (
            <Link
              className={
                paginate.page === 1
                  ? `border-t-2 border-transparent
                    pt-4
                    pr-1
                    inline-flex
                    items-center
                    text-sm
                    font-medium
                    text-gray-700
                    hover:text-gray-700 hover:border-gray-300
                    opacity-20 cursor-not-allowed`
                  : `border-t-2 border-transparent
                    pt-4
                    pr-1
                    inline-flex
                    items-center
                    text-sm
                    font-medium
                    text-gray-700
                    hover:text-gray-700 hover:border-gray-300
                    cursor-pointer`
              }
              href={buildPath(paginate.page - 1)}
            >
              <svg
                className="mr-3 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z"
                  clipRule="evenodd"
                />
              </svg>
              Edellinen
            </Link>
          ) : (
            ''
          )}
        </div>
        <div className="hidden md:-mt-px md:flex">
          {[...Array(paginate.pages)].map((_, i) => (
            <Link
              className={`
                        border-transparent
                        text-gray-500
                        hover:text-gray-700 hover:border-gray-300
                        border-t-2
                        pt-4
                        px-4
                        inline-flex
                        items-center
                        text-sm
                        font-medium
                        cursor-pointer
                        ${paginate.page === i + 1 ? 'text-gray-700 border-gray-300' : ''}`}
              key={`page-` + i + 1}
              href={buildPath(i + 1)}
            >
              {i + 1}
            </Link>
          ))}
        </div>
        <div className="-mt-px w-0 flex-1 flex justify-end">
          {paginate.page < paginate.pages ? (
            <Link
              className={`
                    border-t-2 border-transparent
                    pt-4
                    pl-1
                    inline-flex
                    items-center
                    text-sm
                    font-medium
                    text-gray-700
                    hover:text-gray-700 hover:border-gray-300
                    cursor-pointer
                    ${paginate.page === paginate.pages ? 'opacity-20 cursor-not-allowed' : ''}`}
              href={buildPath(paginate.page + 1)}
            >
              Seuraava
              <svg
                className="ml-3 h-5 w-5 text-gray-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </Link>
          ) : (
            ''
          )}
        </div>
      </nav>
    </>
  );
}

export const MemoizedPaginate = memo(Paginate, paginatePropsAreEqual);
