'use client';

import FilterDropdownItem from '@components/filter/FilterDropdownItem';
import FilterSelectedButton from '@components/filter/FilterSelectedButton';
import OrganizerDropdownItem from '@components/filter/OrganizerDropdownItem';
import { OrganizerObject } from '@eventlr/types/src/Frontend/common';
import { FilterProps } from '@eventlr/types/src/Frontend/filter';
import { Dialog, Disclosure, Menu, Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useDebounced } from '@hooks/useDebounced';
import { useIsMount } from '@hooks/useMount';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { Fragment, useEffect, useState } from 'react';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

const sortOptions = [{ name: 'Alkamispäivä', value: 'date', href: '#', checked: true }];

export default function FilterBar({
  filters,
  organizers,
  organizer,
}: {
  filters: FilterProps[];
  organizers: OrganizerObject[];
  organizer: OrganizerObject;
}) {
  const isMount = useIsMount();
  const [open, setOpen] = useState(false);
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const { replace, refresh } = useRouter();

  const [activeFilter, setActiveFilters] = useState<{ [x: string]: string[] }>(() => {
    const params = new URLSearchParams(searchParams);
    const activeFilters: { [x: string]: string[] } = {};
    for (const filter of filters) {
      const param = params.get(filter.id);
      if (param) {
        activeFilters[filter.id] = param.split(',');
      }
    }
    return activeFilters;
  });

  const debouncedActiveFilters = useDebounced(activeFilter, 300);

  useEffect(() => {
    if (isMount) {
      console.log('first render');
      return;
    }

    console.log('active filters changed', debouncedActiveFilters);

    const params = new URLSearchParams(searchParams);
    let hasAny = false;
    for (const filter of filters) {
      const active = debouncedActiveFilters[filter.id];
      if (active && active.length > 0) {
        params.set(filter.id, active.join(','));
        hasAny = true;
      } else if (params.has(filter.id)) {
        params.delete(filter.id);
        hasAny = true;
      }
    }

    if (hasAny) {
      params.delete('page');
      const p = params.toString();
      replace(`${pathname}${p != '' ? `?${p}` : ''}`);
      refresh();
    }
  }, [debouncedActiveFilters]);

  const toggleActiveOrganizer = (organizer: OrganizerObject) => {
    const params = new URLSearchParams(searchParams);
    let path = pathname;
    if (path.startsWith('/' + organizer.slug)) {
      path = path.slice(organizer.slug.length + 1);
    } else {
      path = `/${organizer.slug}${path}`;
    }

    params.delete('page');
    const p = params.toString();
    replace(`${path}${p != '' ? `?${p}` : ''}`);
    refresh();
  };

  const toggleActiveFilter = (sectionId: string, optionId: string) => {
    if (!activeFilter) {
      setActiveFilters({ [sectionId]: [optionId] });
      return;
    }

    const active = activeFilter[sectionId];
    if (!active) {
      activeFilter[sectionId] = [optionId];
      setActiveFilters({ ...activeFilter });
      return;
    }

    if (active.indexOf(optionId) === -1) {
      active.push(optionId);
    } else {
      active.splice(active.indexOf(optionId), 1);
    }

    setActiveFilters({ ...activeFilter });
  };

  const countActiveFiltersInSection = (sectionId: string) => {
    return activeFilter[sectionId]?.length ?? 0;
  };

  const getActiveFilters = () => {
    const formatted = [];
    for (const filter of filters) {
      const active = activeFilter[filter.id];
      if (active) {
        for (const option of filter.options) {
          if (active.includes(option.value)) {
            formatted.push({
              label: option.label,
              optionId: option.value,
              sectionId: filter.id,
            });
          }
        }
      }
    }

    return formatted;
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 sm:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel
                className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto"
                style={{ marginTop: '135px' }}
              >
                <div className="px-4 flex items-center justify-between">
                  <h2 className="text-lg font-medium text-gray-900">Suotimet</h2>
                  <button
                    type="button"
                    className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Sulje valikko</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4">
                  {organizers.length > 0 && (
                    <Disclosure as="div" key="organizer" className="border-t border-gray-200 px-4 py-6">
                      {({ open }) => (
                        <>
                          <h3 className="-mx-2 -my-3 flow-root">
                            <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400">
                              <span className="font-medium text-gray-900">Järjestäjä</span>
                              <span className="ml-6 flex items-center">
                                <ChevronDownIcon
                                  className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-6">
                              {organizers.map((o) => (
                                <OrganizerDropdownItem
                                  key={`form-section-dd-up-${o.id}`}
                                  organizer={o}
                                  toggleActiveOrganizer={toggleActiveOrganizer}
                                />
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  )}
                  {filters.map((section) => (
                    <Disclosure as="div" key={section.name} className="border-t border-gray-200 px-4 py-6">
                      {({ open }) => (
                        <>
                          <h3 className="-mx-2 -my-3 flow-root">
                            <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400">
                              <span className="font-medium text-gray-900">{section.name}</span>
                              {countActiveFiltersInSection(section.id) > 0 && (
                                <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                                  {countActiveFiltersInSection(section.id)}
                                </span>
                              )}
                              <span className="ml-6 flex items-center">
                                <ChevronDownIcon
                                  className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-6">
                              {section.options.map((option, optionIdx) => (
                                <FilterDropdownItem
                                  key={`form-section-dd-up-${option.value}`}
                                  option={option}
                                  index={optionIdx}
                                  section={section}
                                  toggleActiveFilter={toggleActiveFilter}
                                />
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <section aria-labelledby="filter-heading" className="mb-6">
        <h2 id="filter-heading" className="sr-only">
          Suotimet
        </h2>

        <div className="relative z-10 bg-white border-b border-gray-200 pb-4 mt-4">
          <div className="max-w-7xl mx-auto px-4 flex items-center justify-between sm:px-6 lg:px-8">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Järjestys
                  <ChevronDownIcon
                    className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-left absolute left-0 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map((option) => (
                      <Menu.Item key={option.name}>
                        {({ focus }) => (
                          <a
                            href={option.href}
                            className={classNames(
                              option?.checked ? 'font-medium text-gray-900' : 'text-gray-500',
                              focus ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm',
                            )}
                          >
                            {option.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            <button
              type="button"
              className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
              onClick={() => setOpen(true)}
            >
              Suotimet
            </button>

            <div className="hidden sm:block">
              <div className="flow-root">
                <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
                  {organizers.length > 0 && (
                    <Popover key="organizer" className="px-4 relative inline-block text-left">
                      <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        <span>Järjestäjä</span>
                        <ChevronDownIcon
                          className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <form key={`form-section-organizer`} className="space-y-4">
                            {organizers.map((o) => (
                              <OrganizerDropdownItem
                                key={`form-section-dd-up-${o.id}`}
                                organizer={o}
                                toggleActiveOrganizer={toggleActiveOrganizer}
                              />
                            ))}
                          </form>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  )}
                  {filters.map((section) => (
                    <Popover key={section.name} className="px-4 relative inline-block text-left">
                      <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        <span>{section.name}</span>
                        {countActiveFiltersInSection(section.id) > 0 && (
                          <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                            {countActiveFiltersInSection(section.id)}
                          </span>
                        )}
                        <ChevronDownIcon
                          className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Popover.Button>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <form key={`form-section-${section.id}`} className="space-y-4">
                            {section.options.map((option, optionIdx) => (
                              <FilterDropdownItem
                                key={`form-section-dd-${option.value}`}
                                option={option}
                                index={optionIdx}
                                section={section}
                                toggleActiveFilter={toggleActiveFilter}
                              />
                            ))}
                          </form>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  ))}
                </Popover.Group>
              </div>
            </div>
          </div>
        </div>

        {/* Active filters */}
        {Object.entries(getActiveFilters()).length > 0 && (
          <div className="bg-gray-100">
            <div className="max-w-7xl mx-auto py-3 px-4 sm:flex sm:items-center sm:px-6 lg:px-8">
              <h3 className="text-xs font-semibold uppercase tracking-wide text-gray-500">
                Suotimet
                <span className="sr-only">, aktiivisena</span>
              </h3>

              <div aria-hidden="true" className="hidden w-px h-5 bg-gray-300 sm:block sm:ml-4" />

              <div className="mt-2 sm:mt-0 sm:ml-4">
                <div className="-m-1 flex flex-wrap items-center">
                  {getActiveFilters().map((activeFilter) => (
                    <FilterSelectedButton
                      key={`filter-button-${activeFilter.sectionId}-${activeFilter.optionId}`}
                      label={activeFilter.label}
                      optionId={activeFilter.optionId}
                      sectionId={activeFilter.sectionId}
                      toggleActiveFilter={toggleActiveFilter}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
}
