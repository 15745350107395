'use client';

import { OrganizerObject, PaginateProps } from '@eventlr/types/src/Frontend/common';
import { Event, MultiEvent, TagCloudProps, ViewType } from '@eventlr/types/src/Frontend/event';
import { EventCommonProps } from '@lib/events';
import { Suspense } from 'react';

import Cover from '@components/cover';
import Card from '@components/event/card';
import EventCard from '@components/event/eventCard';
import MultiCard from '@components/event/multiEventCard';
import FilterBar from '@components/filter/FilterBar';
import { SearchBar } from '@components/filter/SearchBar';
import { MemoizedPaginate } from '@components/paginate';
import { EventDTO } from '@eventlr/common/src/types/event';
import { FilterOption } from '@eventlr/types/src/Frontend/filter';
import { SettingProps } from '@lib/settings';
import { IUser } from '@lib/user';
import { ISetting } from '@stores/settingStore';

export default function EventList({
  newEvents,
  events,
  commonEventData,
  organizer,
  settings,
  paginate,
  session,
  filters,
  organizers,
}: {
  newEvents: EventDTO[];
  events: Array<Event | (MultiEvent & TagCloudProps)>;
  commonEventData: EventCommonProps;
  organizer: OrganizerObject;
  paginate: PaginateProps;
  settings: SettingProps[];
  session: IUser;
  filters: { id: string; name: string; options: FilterOption[] }[];
  organizers: OrganizerObject[];
}) {
  function resolveViewType(event: Event | MultiEvent) {
    if (event.view_type == ViewType.SingleEvent) {
      return <Card event={event as Event & TagCloudProps} organizer={organizer} key={event.id} settings={settings} />;
    } else if (event.view_type == ViewType.MultiEvent) {
      return (
        <MultiCard
          event={event as MultiEvent & TagCloudProps}
          organizer={organizer}
          key={event.id}
          settings={settings}
        />
      );
    }
    return '';
  }

  const coverImage = settings?.find((s: ISetting) => s.key === 'site-banner')?.value ?? '';
  const eventDesc = settings?.find((s: ISetting) => s.key === 'event-description')?.value ?? '';

  return (
    <Suspense fallback={<p>Ladataan tapahtumia</p>}>
      <section className="relative w-full h-full py-40 min-h-screen">
        <Cover imgSrc={coverImage} />
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 -mt-36">
          <div className="bg-white rounded-lg">
            <div className="max-w-screen-xl mx-auto p-6">
              <div className="pb-5">
                <h1 className="text-4xl font-extrabold tracking-tight text-gray-900">Koulutukset</h1>
                <p className="mt-4 text-base text-gray-500" dangerouslySetInnerHTML={{ __html: eventDesc }}></p>
              </div>
              <div className="max-w-screen-xl mx-auto">
                <div className="mb-5">
                  <SearchBar />
                  <FilterBar filters={filters} organizers={organizers} organizer={organizer} />
                </div>
                {newEvents.length > 0 && (
                  <div className="mb-5">
                    <h2 className="text-2xl font-bold">Kiinnostavat tapahtumat</h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
                      {newEvents.map((item) => (
                        <EventCard key={item.id} event={item} organizer={organizer} settings={settings} />
                      ))}
                    </div>
                  </div>
                )}
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
                  {events.map((item) => resolveViewType(item))}
                </div>
                <MemoizedPaginate paginate={paginate} />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Suspense>
  );
}
