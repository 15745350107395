import { DateTime } from 'luxon';

// components

import Image from 'next/image';
import Link from 'next/link';

import { OrganizerObject } from '@eventlr/types/src/Frontend/common';
import { Event, TagCloudProps } from '@eventlr/types/src/Frontend/event';
import { SettingProps } from '@lib/settings';

export default function CardItem({
  event,
  organizer,
  settings,
}: {
  event: Event & TagCloudProps;
  organizer: OrganizerObject | null;
  settings: SettingProps[];
}) {
  function statusInfo(event: Event) {
    if (['FOR_SALE', 'FULLY_BOOKED_OPEN_QUEUE', 'FULLY_BOOKED'].includes(event.state)) {
      if (event.statistics.seats_available > 0 || event.statistics.seats_available === -1) {
        return (
          <span className="ml-1">
            Paikkoja vapaana{' '}
            {event.statistics.seats_available === -1 ? 'rajattomasti' : event.statistics.seats_available}
          </span>
        );
      }

      if (
        event.statistics.seats_available <= 0 &&
        event.statistics.seats_available !== -1 &&
        (event.statistics.seats_for_reserve > 0 || event.statistics.seats_for_reserve === -1)
      ) {
        return (
          <span className="ml-1">
            Varasijoja vapaana{' '}
            {event.statistics.seats_for_reserve === -1 ? 'rajattomasti' : event.statistics.seats_for_reserve}
          </span>
        );
      }

      if (
        event.statistics.seats_available <= 0 &&
        event.statistics.seats_for_reserve <= 0 &&
        event.statistics.seats_for_reserve != -1
      ) {
        return <span className="ml-1">Koulutus on täynnä</span>;
      }
    }

    if (event.state === 'SALE_ENDED') {
      return <span className="ml-1">Ilmoittautuminen on päättynyt</span>;
    }

    if (event.state === 'CANCELLED') {
      return <span className="ml-1">Koulutus on peruttu</span>;
    }

    return <span className="ml-1">Ilmoittauminen ei ole alkanut</span>;
  }

  const startDate = DateTime.fromISO(event.start_date).setLocale('fi');
  const coverImage = settings.find((s) => s.key === 'default-course-cover')?.value ?? '';

  return (
    <>
      <div className="relative rounded overflow-hidden shadow-lg">
        <Link className="block" href={`/${event.organizer?.slug}/${event.slug}`}>
          <div className="relative">
            <div className="relative w-full h-72">
              <Image
                fill
                className="w-full"
                src={event.cover?.url ?? coverImage}
                style={{ objectFit: 'cover' }}
                alt={event.cover?.alt ?? 'Koulutuksen kansikuva'}
              />
            </div>
            <div className="absolute bottom-0 top-0 right-0 left-0"></div>
            {event.independent_record ? (
              <div>
                <div className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                  Itsenäisesti suoritettava
                </div>
              </div>
            ) : (
              ''
            )}
            <div>
              <div className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                <span className="font-bold">{startDate.day}</span>
                <small>{startDate.monthLong}</small>
              </div>
            </div>
            {event.cover?.copyright && event.cover?.copyright != '' && (
              <div>
                <div className="absolute right-0 px-4 py-2 text-gray text-xs line-clamp-1" style={{ bottom: -25 }}>
                  {event.cover.copyright}
                </div>
              </div>
            )}
          </div>
        </Link>
        <div className="px-6 py-4 pt-6 flex-grow mb-10">
          <Link
            className="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out line-clamp-2"
            href={`/${event.organizer?.slug}/${event.slug}`}
          >
            {event.name}
          </Link>
          <div className="text-gray-800 mt-5 text-sm" dangerouslySetInnerHTML={{ __html: event.ingress }}></div>
        </div>
        <div className="w-full text-gray-800 font-bold py-2 px-6 rounded inline-flex items-center bottom-0 absolute">
          <span className="py-1 text-sm font-regular text-gray-900 mr-1 flex flex-row justify-between items-center">
            {statusInfo(event)}
          </span>
        </div>
      </div>
    </>
  );
}
