import { DateTime } from 'luxon';

import Image from 'next/image';

// components

import { OrganizerObject } from '@eventlr/types/src/Frontend/common';
import { MultiEvent, TagCloudProps } from '@eventlr/types/src/Frontend/event';
import { SettingProps } from '@lib/settings';

export default function MultiEventCard({
  event,
  organizer,
  settings,
}: {
  event: MultiEvent & TagCloudProps;
  organizer: OrganizerObject | null;
  settings: SettingProps[];
}) {
  function getPrefix(tenantID: number, path: string | undefined) {
    switch (tenantID) {
      case 1:
        return 'tampere';
      case 2:
        return 'osake';
      default:
        return path;
    }
  }
  const startDate = DateTime.fromISO(event.start_date).setLocale('fi');
  const coverImage = settings.find((s) => s.key === 'default-course-cover')?.value ?? '';

  return (
    <>
      <div className="relative rounded overflow-hidden shadow-lg">
        <a
          href={`https://${getPrefix(event.tenant_id, organizer?.slug)}.eeventti.fi/koulutukset/${event.slug}`}
          className="block"
        >
          <div className="relative">
            <div className="relative w-full h-72">
              <Image
                className="w-full"
                fill
                unoptimized={true}
                src={event.cover?.url ?? coverImage}
                style={{ objectFit: 'cover' }}
                alt={event.cover?.alt ?? 'Koulutuksen kansikuva'}
              />
            </div>
            <div className="absolute bottom-0 top-0 right-0 left-0"></div>
            {true ? (
              <div>
                <div className="absolute bottom-0 left-0 bg-indigo-600 px-4 py-2 text-white text-sm hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                  Tapahtuma
                </div>
              </div>
            ) : (
              ''
            )}
            <div>
              <div className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                <span className="font-bold">{startDate.day}</span>
                <small>{startDate.monthLong}</small>
              </div>
            </div>
            {event.cover?.copyright && event.cover?.copyright != '' && (
              <div>
                <div className="absolute right-0 px-4 py-2 text-gray text-xs line-clamp-1" style={{ bottom: -25 }}>
                  {event.cover.copyright}
                </div>
              </div>
            )}
          </div>
        </a>
        <div className="px-6 py-4 flex-grow mb-10">
          <a
            href={`https://${getPrefix(event.tenant_id, organizer?.slug)}.eeventti.fi/koulutukset/${event.slug}`}
            className="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out line-clamp-2"
          >
            {event.name}
          </a>
          <div className="text-gray-800 mt-5 text-sm" dangerouslySetInnerHTML={{ __html: event.ingress }}></div>
        </div>
        <div className="w-full text-gray-800 font-bold py-2 px-6 rounded inline-flex items-center bottom-0 absolute">
          <span className="py-1 text-sm font-regular text-gray-900 mr-1 flex flex-row justify-between items-center">
            {false ? <span className="ml-1">Paikkoja vapaana 0</span> : ''}
          </span>
        </div>
      </div>
    </>
  );
}
