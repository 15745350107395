import { DateTime } from 'luxon';

// components

import Image from 'next/image';
import Link from 'next/link';

import { EventDTO } from '@eventlr/common/src/types/event';
import { OrganizerObject } from '@eventlr/types/src/Frontend/common';
import { SettingProps } from '@lib/settings';

export default function EventCard({
  event,
  organizer,
  settings,
}: {
  event: EventDTO;
  organizer: OrganizerObject | null;
  settings: SettingProps[];
}) {
  let startDate: DateTime | null = null;
  if (event.instances?.[0]?.start_at) {
    startDate = DateTime.fromISO(event.instances?.[0]?.start_at).setLocale('fi');
  }

  const coverImage = settings.find((s) => s.key === 'default-course-cover')?.value ?? '';

  return (
    <>
      <div className="relative rounded overflow-hidden shadow-lg">
        {event.routes[0] && event.routes[0].route && (
          <Link className="block" href={`/${event.organizer.slug}${event.routes[0].route}`}>
            <div className="relative">
              <div className="relative w-full h-72">
                <Image
                  fill
                  className="w-full"
                  src={`${process.env.NEXT_PUBLIC_CDN_DOMAIN}/${
                    event.assets.find((a) => a.id === event.cover_asset_id)?.url ?? coverImage
                  }`}
                  style={{ objectFit: 'cover' }}
                  alt={
                    event.assets.find((a) => a.id === event.cover_asset_id)?.alternative_text ?? 'Tapahtuman kansikuva'
                  }
                />
              </div>
              <div className="absolute bottom-0 top-0 right-0 left-0"></div>
              <div>
                <div className="text-sm absolute top-0 right-0 bg-indigo-600 px-4 text-white rounded-full h-16 w-16 flex flex-col items-center justify-center mt-3 mr-3 hover:bg-white hover:text-indigo-600 transition duration-500 ease-in-out">
                  <span className="font-bold">{startDate?.day}</span>
                  <small>{startDate?.monthLong}</small>
                </div>
              </div>
              {event.assets.find((a) => a.id === event.cover_asset_id)?.copyright_text != '' && (
                <div>
                  <div className="absolute right-0 px-4 py-2 text-gray text-xs line-clamp-1" style={{ bottom: -25 }}>
                    {event.assets.find((a) => a.id === event.cover_asset_id)?.copyright_text}
                  </div>
                </div>
              )}
            </div>
          </Link>
        )}
        <div className="px-6 py-4 pt-6 flex-grow mb-10">
          {event.routes[0] && event.routes[0].route && (
            <Link
              className="font-semibold text-lg inline-block hover:text-indigo-600 transition duration-500 ease-in-out line-clamp-2"
              href={`/${event.organizer.slug}${event.routes[0].route}`}
            >
              {event.name}
            </Link>
          )}
          <div className="text-gray-800 mt-5 text-sm" dangerouslySetInnerHTML={{ __html: event.kicker ?? '' }}></div>
        </div>
        <div className="w-full text-gray-800 font-bold py-2 px-6 rounded inline-flex items-center bottom-0 absolute">
          <span className="py-1 text-sm font-regular text-gray-900 mr-1 flex flex-row justify-between items-center">
            {event.sale_start_at && (
              <span className="ml-1">
                Ilmoittautuminen alkaa {DateTime.fromISO(event.sale_start_at).setLocale('fi').toLocaleString()}
              </span>
            )}
          </span>
        </div>
      </div>
    </>
  );
}
