import { OrganizerObject } from '@eventlr/types/src/Frontend/common';
import { usePathname } from 'next/navigation';
import { useState } from 'react';

type Section = {
  id: string;
};

function isActiveFilter(pathname: string, organizer: OrganizerObject) {
  // split pathname to get the organizer name
  const pathParts = pathname.slice(1).split('/');
  const organizerName = pathParts[0];

  if (organizerName === organizer.slug) {
    return true;
  }
  return false;
}

export default function OrganizerDropdownItem({
  organizer,
  toggleActiveOrganizer,
}: {
  organizer: OrganizerObject;
  toggleActiveOrganizer: (organizer: OrganizerObject) => void;
}) {
  const pathname = usePathname();

  const [checked, setChecked] = useState<boolean>(isActiveFilter(pathname, organizer));

  function handleChange(organizer: OrganizerObject, checked: boolean) {
    setChecked(checked);
    toggleActiveOrganizer(organizer);
  }

  return (
    <div key={`item-wrapper-${organizer.id}`} className="flex items-center">
      <input
        id={`filter-${organizer.id}`}
        name={`${organizer.id}[]`}
        checked={checked}
        type="checkbox"
        key={`item-input-${organizer.id}`}
        onChange={(e) => handleChange(organizer, e.target.checked)}
        className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
      />
      <label
        htmlFor={`filter-${organizer.id}`}
        key={`item-label-${organizer.id}`}
        className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap w-full"
      >
        {organizer.name}
      </label>
    </div>
  );
}
