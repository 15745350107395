import { FilterOption } from '@eventlr/types/src/Frontend/filter';
import { useSearchParams } from 'next/navigation';
import { useState } from 'react';

type Section = {
  id: string;
};

function isActiveFilter(searchParams: URLSearchParams, sectionId: string, optionId: string) {
  const param = searchParams.get(sectionId);

  console.log('isActiveFilter', param, sectionId, optionId);

  if (!param) {
    return false;
  }

  const paramArray = param.split(',');
  console.log('paramArray', paramArray);
  for (const p of paramArray) {
    console.log('p', p);
    if (p === optionId) {
      console.log('return true');
      return true;
    }
  }

  return false;
}

export default function FilterDropdownItem({
  section,
  option,
  index,
  toggleActiveFilter,
}: {
  section: Section;
  option: FilterOption;
  index: number;
  toggleActiveFilter: (sectionId: string, optionId: string) => void;
}) {
  const searchParams = useSearchParams();

  const [checked, setChecked] = useState<boolean>(isActiveFilter(searchParams, section.id, option.value));

  function handleChange(sectionId: string, optionId: string, checked: boolean) {
    const params = new URLSearchParams(searchParams);
    const param = params.get(sectionId);
    if (!param) {
      params.set(sectionId, optionId);
      setChecked(true);
    } else {
      const paramArray = param.split(',');
      if (checked) {
        paramArray.push(optionId);
        setChecked(true);
      } else {
        const index = paramArray.indexOf(optionId);
        if (index > -1) {
          paramArray.splice(index, 1);
        }
        setChecked(false);
      }

      if (paramArray.length === 0) {
        setChecked(false);
      }
    }

    toggleActiveFilter(sectionId, optionId);
  }

  return (
    <div key={`item-wrapper-${section.id}-${option.value}`} className="flex items-center">
      <input
        id={`filter-${section.id}-${index}`}
        name={`${section.id}[]`}
        defaultValue={option.value}
        checked={checked}
        type="checkbox"
        key={`item-input-${section.id}-${option.value}`}
        onChange={(e) => handleChange(section.id, option.value, e.target.checked)}
        className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
      />
      <label
        htmlFor={`filter-${section.id}-${index}`}
        key={`item-label-${section.id}-${option.value}`}
        className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap w-full"
      >
        {option.label}
      </label>
    </div>
  );
}
