export default function CoverHalf({ imgSrc }: { imgSrc: string }) {
  return (
    <>
      <div
        className="w-full h-full bg-no-repeat bg-full"
        style={{ marginTop: '-160px', backgroundImage: `url(${imgSrc})`, height: '280px' }}
      ></div>
    </>
  );
}
