'use client';

import { useDebounced } from '@hooks/useDebounced';
import { useIsMount } from '@hooks/useMount';
import { usePathname, useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

export const SearchBar = () => {
  const isMount = useIsMount();
  const pathname = usePathname();
  const { replace } = useRouter();
  const searchParams = useSearchParams();

  const [query, setQuery] = useState(searchParams.get('search') ?? '');
  const debouncedQuery = useDebounced(query, 300);

  useEffect(() => {
    if (isMount) {
      return;
    }
    const params = new URLSearchParams(searchParams);
    if (debouncedQuery) {
      params.set('search', debouncedQuery);
    } else {
      params.delete('search');
    }
    params.delete('page');
    replace(`${pathname}?${params.toString()}`);
  }, [debouncedQuery]);

  return (
    <div className="mb-5">
      <label htmlFor="search" className="sr-only">
        Hae
      </label>
      <input
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        id="search"
        name="search"
        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        placeholder="Search the data..."
      />
    </div>
  );
};
